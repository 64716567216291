<template>
  <a-select size="large" v-bind="{ ...$attrs, ...$props }"/>
</template>

<script>
export default {
  name: 'UiSelect',
  inheritAttrs: false
}
</script>

<style lang="scss">
</style>