<template>
  <a-modal title="Let's fill your activity" :visible="visible">
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :hideRequiredMark="true"
      :label-col="labelCol"
    >
      <a-form-item label="Activity date" name="date">
        <ui-date-picker
          v-model:value="formState.date"
          @change="handleDateChange"
          :format="dateFormat"
          :disabled="mode === MODES.EDIT"
          :disabledDate="disabledDate"
          class="same-width"
        />
      </a-form-item>
      <div class="inline-items">
        <div class="ant-form-item-label">
          <label class="ant-form-item-required bold"
            >Activity Hours ({{ currentTimezone }})</label
          >
        </div>
        <div class="flex">
          <a-form-item label="From:" name="startTime">
            <ui-time-picker
              v-model:value="formState.startTime"
              placeholder="00:00"
              format="HH:mm"
              :minuteStep="5"
              :disabled="mode === MODES.EDIT"
              :disabledHours="disabledHours"
              :disabledMinutes="disabledMinutes"
              class="mr-16"
            />
          </a-form-item>
          <a-form-item label="To:" name="endTime">
            <ui-time-picker
              v-model:value="formState.endTime"
              placeholder="00:00"
              :minuteStep="5"
              :disabled="mode === MODES.EDIT"
              format="HH:mm"
            />
          </a-form-item>
        </div>
        <div class="night-hours">
          Your night hours in current timezone is {{ nightStart }}-{{
            nightEnd
          }}
        </div>
      </div>
      <a-form-item label="Activity" name="activity">
        <ui-select
          v-model:value="formState.activity"
          :options="activityOptions"
          placeholder="Select the activity"
          class="select same-width"
          @change="handleActivityChange"
        />
      </a-form-item>
      <template v-if="activityPurposes?.length">
        <a-form-item label="Work’s Purpose" name="purpose">
          <ui-select
            v-model:value="formState.purpose"
            :options="activityPurposes"
            placeholder="Select the purpose"
            class="select same-width"
          />
        </a-form-item>
      </template>
      <template v-if="groupNameVisibility">
        <a-form-item label="Group's name" name="groupName">
          <ui-select
            v-model:value="formState.groupName"
            :options="groupOptions"
            placeholder="Select the group's name"
            class="select same-width"
          />
        </a-form-item>
      </template>
      <template v-if="groupLevelVisibility">
        <a-form-item label="Group's level" name="groupLevel">
          <a-radio-group
            v-model:value="formState.groupLevel"
            class="same-width"
          >
            <a-radio-button value="a1" class="text-center w-25"
              >A1</a-radio-button
            >
            <a-radio-button value="a2" class="text-center w-25"
              >A2</a-radio-button
            >
            <a-radio-button value="b1" class="text-center w-25"
              >B1</a-radio-button
            >
            <a-radio-button value="b2" class="text-center w-25"
              >B2</a-radio-button
            >
          </a-radio-group>
        </a-form-item>
      </template>
      <a-form-item label="Notes" name="notes">
        <a-textarea
          placeholder="Smth"
          size="large"
          class="same-width"
          v-model:value="formState.notes"
        />
      </a-form-item>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">Cancel</a-button>
      <a-button :loading="loading" @click="handleSend" type="primary">{{
        okText
      }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import UiDatePicker from '../ui/UiDatePicker.vue'
import UiTimePicker from '../ui/UiTimePicker.vue'
import UiSelect from '../ui/UiSelect.vue'
import { activityOptions, activityParams } from '../../constants/activities'
import purposeParamsMap from '../../constants/purpose-params-map'
import groups from '../../constants/groups'
import { mapActions } from 'vuex'
import moment from 'moment-timezone'

export const MODES = {
  ADD: 'add',
  EDIT: 'edit',
}

export default {
  name: 'TimeTrackModal',
  props: {
    visible: Boolean,
    mode: {
      type: String,
      default: MODES.ADD,
    },
    editData: {
      type: Object,
      default: () => ({}),
    },
    tableData: {
      type: Array,
      required: true,
    },
    teacherTimezone: {
      type: String,
    },
    freezeDate: {
      type: Object,
      required: true,
    },
    nearestEndOfPeriod: {
      type: Object,
      required: true,
    },
  },
  components: {
    UiDatePicker,
    UiTimePicker,
    UiSelect,
  },
  data() {
    const date = moment(this.editData?.startTime)

    const startTime = this.editData.startTime
      ? moment(this.editData.startTime)
      : null

    const endTime = this.editData.endTime ? moment(this.editData.endTime) : null

    return {
      MODES,
      currentTimezone: moment.tz.guess(),
      labelCol: {
        class: 'custom-label',
      },
      dateFormat: 'YYYY/MM/DD',
      formState: {
        date,
        startTime,
        endTime,
        activity: this.editData.activity,
        purpose: this.editData.purpose,
        groupName: this.editData.groupName,
        groupLevel: this.editData.groupLevel,
        notes: this.editData.notes,
      },
      rules: {
        date: [
          {
            required: true,
            message: 'Please choose Activity date',
            trigger: 'change',
            async validator(rule, value) {
              if (!value) return Promise.reject()

              return Promise.resolve()
            },
          },
        ],
        startTime: [
          {
            required: true,
            trigger: 'change',
            validator: async (rule, value) => {
              if (!value) {
                rule.message = 'Сhoose start time'
                return Promise.reject()
              } else if (value.isSame(this.formState.endTime)) {
                rule.message = 'Duration is 0'
                return Promise.reject()
              }

              return Promise.resolve()
            },
          },
        ],
        endTime: [
          {
            required: true,
            trigger: 'change',
            validator: async (rule, value) => {
              if (!value) {
                rule.message = 'Сhoose end time'
                return Promise.reject()
              } else if (value.isSame(this.formState.startTime)) {
                rule.message = 'Duration is 0'
                return Promise.reject()
              }
              // else if (value.diff(this.formState.startTime, 'h') > 4) {
              //   rule.message = 'Track should be less than 4h'
              //   return Promise.reject()
              // }

              return Promise.resolve()
            },
          },
        ],
        activity: [
          { required: true, message: 'Сhoose activity', trigger: 'change' },
        ],
        purpose: [
          { required: true, message: 'Сhoose purpose', trigger: 'change' },
        ],
        groupName: [
          {
            required: true,
            message: 'Сhoose group name',
            trigger: 'change',
          },
        ],
        groupLevel: [
          {
            required: true,
            message: 'Сhoose group level',
            trigger: 'change',
          },
        ],
      },
      activityOptions,
      groupOptions: groups,
      loading: false,
    }
  },
  computed: {
    okText() {
      return this.mode === MODES.ADD ? 'Add' : 'Update'
    },
    activityParams() {
      return activityParams[this.formState?.activity]
    },
    activityPurposes() {
      return this.activityParams?.purposes
    },
    groupNameVisibility() {
      return (
        this.activityParams?.hasGroupName ??
        purposeParamsMap[this.formState?.purpose]?.hasGroupName
      )
    },
    groupLevelVisibility() {
      return (
        this.activityParams?.hasGroupLevel ??
        purposeParamsMap[this.formState?.purpose]?.hasGroupLevel
      )
    },
    profileTimezone() {
      return this.editData?.staff?.timezone ?? this.teacherTimezone
    },
    nightStart() {
      if (this.profileTimezone) {
        return moment()
          .tz(this.profileTimezone)
          .hours(22)
          .minutes(0)
          .tz(this.currentTimezone)
          .format('HH:mm')
      }

      return '--'
    },
    nightEnd() {
      if (this.profileTimezone) {
        return moment()
          .tz(this.profileTimezone)
          .hours(6)
          .minutes(0)
          .tz(this.currentTimezone)
          .format('HH:mm')
      }

      return '--'
    },
  },
  watch: {
    editData(newVal) {
      if (newVal) {
        this.reInitData()
      }
    },
  },
  methods: {
    ...mapActions('time-tracker', {
      createRow: 'create',
      patchRow: 'patch',
    }),
    handleActivityChange() {
      this.formState.purpose = undefined
    },
    disabledDate(currentDate) {
      const endOfDay = moment(currentDate).endOf('day')

      if (
        endOfDay.diff(this.freezeDate) < 0 &&
        endOfDay.diff(this.nearestEndOfPeriod, 'M') < 0
      ) {
        return true
      }

      return false
    },
    disabledHours() {
      const result = []
      const startOfDay = moment(this.formState.date).startOf('day')
      const endOfDay = moment(this.formState.date).endOf('day')

      if (
        // endOfDay < freezeDate
        endOfDay.diff(this.freezeDate) < 0 &&
        // endOfDay > nearestEndOfPeriod
        endOfDay.diff(this.nearestEndOfPeriod, 'M') < 0
      ) {
        // Fix: end of day is always 23:59:59, so we add 1 sec to "fix" it
        const end = moment(this.nearestEndOfPeriod).add(1, 's')
        const between = moment(startOfDay)

        while (end.diff(between, 'h') > 0) {
          result.push(between.hours())
          between.add(1, 'h').startOf('h')
        }
      }

      return result
    },
    disabledMinutes(selectedHour) {
      const endOfDay = moment(this.formState.date).endOf('day')

      if (
        endOfDay.diff(this.freezeDate) < 0 &&
        endOfDay.diff(this.nearestEndOfPeriod, 'M') < 0 &&
        selectedHour
      ) {
        // console.log('selectedHour', selectedHour, 'this.nearestEndOfPeriod', moment(this.nearestEndOfPeriod).hours())
        const end = moment(this.nearestEndOfPeriod)

        if (selectedHour < end.hours()) {
          return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
        }
      }

      return []
    },
    handleDateChange() {
      this.formState.startTime = null
      this.formState.endTime = null
    },
    reInitData() {
      const date = moment(this.editData.startTime)

      const startTime = this.editData.startTime
        ? moment(this.editData.startTime)
        : null

      const endTime = this.editData.endTime
        ? moment(this.editData.endTime)
        : null

      this.formState = {
        ...this.editData,
        startTime,
        endTime,
        date,
      }
    },
    handleCancel() {
      this.$refs.formRef.resetFields()
      this.$emit('cancel')
    },
    async handleSend() {
      try {
        await this.$refs.formRef.validate()
      } catch (error) {
        console.log('error', error)
        return
      }

      this.loading = true
      const { date: activityDate, startTime, endTime } = this.formState

      if (startTime.diff(endTime) > 0) {
        // Cover night shift
        this.formState.endTime = moment(activityDate).add(1, 'day')
      } else {
        this.formState.endTime = moment(activityDate)
      }

      this.formState.endTime
        .hours(endTime.hours())
        .minutes(endTime.minutes())
        .seconds(0)
        .milliseconds(0)

      // Cover end = 00.00, should be 23.59
      if (endTime.hours() === 0 && endTime.minutes() === 0) {
        this.formState.endTime = moment(activityDate).endOf('day')
      }

      this.formState.startTime = moment(activityDate)
        .hours(startTime.hours())
        .minutes(startTime.minutes())
        .seconds(0)
        .milliseconds(0)

      // eslint-disable-next-line no-unused-vars
      const { date, ...payload } = this.formState
      let response

      try {
        // TODO: payload contains too much trash info, remove it
        if (this.mode === MODES.ADD) {
          response = await this.createRow(payload)
        } else {
          response = await this.patchRow([this.editData.id, payload])
        }
      } catch (error) {
        console.error(error)
        alert(`Server error! ${error.message}`)
        return
      } finally {
        this.loading = false
      }

      this.$emit('ok', response)
      this.$refs.formRef.resetFields()
    },
  },
}
</script>

<style lang="scss">
.custom-label {
  width: 100% !important;
  text-align: left;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.inline-items {
  .custom-label {
    width: inherit !important;
    font-weight: 400;
  }
}

.ant-calendar-prev-year-btn,
.ant-calendar-next-year-btn {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.bold {
  font-weight: 500;
}

.select {
  width: 260px;
}

.same-width {
  width: 348px;
}

.w-25 {
  width: 25%;
}

.night-hours {
  color: gray;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
</style>
