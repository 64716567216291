export default [
  {
    value: 'Batch 10 Goethe',
    label: 'Batch 10 Goethe',
  },
  {
    value: 'Batch 10 Telc',
    label: 'Batch 10 Telc',
  },
  {
    value: 'Batch 14',
    label: 'Batch 14',
  },
  {
    value: 'Batch 15',
    label: 'Batch 15',
  },
  {
    value: 'Batch 18',
    label: 'Batch 18',
  },
  {
    value: 'Batch 19',
    label: 'Batch 19',
  },
  {
    value: 'Batch 20A',
    label: 'Batch 20A',
  },
  {
    value: 'Batch 20B',
    label: 'Batch 20B',
  },
  {
    value: 'Batch 22A',
    label: 'Batch 22A',
  },
  {
    value: 'Batch 22B',
    label: 'Batch 22B',
  },
  {
    value: 'Batch 24A',
    label: 'Batch 24A',
  },
  {
    value: 'Batch 24B',
    label: 'Batch 24B',
  },
  {
    value: 'Batch 25A',
    label: 'Batch 25A',
  },
  {
    value: 'Batch 26A',
    label: 'Batch 26A',
  },
  {
    value: 'Batch 26B',
    label: 'Batch 26B',
  },
  {
    value: 'Batch 26C',
    label: 'Batch 26C',
  },
  {
    value: 'Batch JCJ 1',
    label: 'Batch JCJ 1',
  },
  {
    value: 'Batch 27a',
    label: 'Batch 27a',
  },
  {
    value: 'Batch 27b',
    label: 'Batch 27b',
  },
  {
    value: 'Batch 28a',
    label: 'Batch 28a',
  },
  {
    value: 'Batch 28b',
    label: 'Batch 28b',
  },
]
