import moment from 'moment'

export default function (date, timezone, isEndOfDay) {
  let result

  if (moment().date() < date) {
    result = moment()
      .tz(timezone)
      .subtract(1, 'month')
      .date(date)
      .startOf('day')
  } else {
    result = moment().tz(timezone).date(date).startOf('day')
  }

  return isEndOfDay ? result.tz(timezone).endOf('day') : result
}
