<template>
  <div class="aps-wrap">
    <div class="aps-head">
      <div class="title1 mb-16">Time tracker</div>
      <div class="flex space-between pb-8">
        <div class="flex flex-vertical-center">
          <a-button
            v-if="!isAdmin"
            type="primary"
            ghost
            @click="handleAdd"
            class="mr-8"
            >Add new activity</a-button
          >
        </div>
        <billing-period
          v-model="activityDates"
          :freezeDate="freezeDate"
          :nearestEndOfPeriod="nearestEndOfPeriod"
          :isAdmin="isAdmin"
        />
      </div>
    </div>
    <div class="vertical-scroll">
      <time-track-modal
        v-if="!isAdmin"
        :visible="addModalVisibile"
        :mode="modalModes.ADD"
        :tableData="tableData"
        :teacherTimezone="teacherTimezone"
        :freezeDate="freezeDate"
        :nearestEndOfPeriod="nearestEndOfPeriod"
        @ok="handleResponse"
        @cancel="addModalVisibile = false"
      />
      <time-track-modal
        :visible="editModalVisibile"
        :mode="modalModes.EDIT"
        :editData="editData"
        :tableData="tableData"
        :teacherTimezone="teacherTimezone"
        :freezeDate="freezeDate"
        :nearestEndOfPeriod="nearestEndOfPeriod"
        @ok="handleResponse"
        @cancel="editModalVisibile = false"
      />
      <a-table
        :columns="filteredColumns"
        :data-source="tableData"
        :pagination="pagination"
        :loading="loading"
        :bordered="true"
        rowKey="id"
        size="small"
        :scroll="tableScroll"
        @change="handleTableChange"
      >
        <template #date="{ record }">
          <span>{{
            moment(record.startTime).startOf('day').format('MMM DD, YYYY')
          }}</span>
        </template>

        <template #start="{ record }">
          <span class="mr-8">{{
            moment(record.startTime).format('HH:mm')
          }}</span>
        </template>

        <template #end="{ record }">
          <span class="mr-8">{{ moment(record.endTime).format('HH:mm') }}</span>
        </template>

        <template #day-hours="{ record }">
          <span>{{ record.dayHours }}</span>
          <template v-if="record.hasNightShiftOnStart">
            <span class="gray-text mr-8">({{ record.excludedHours }}h)</span>
            <a-tooltip>
              <template #title>
                These hours were recorded in the last month
              </template>
              <InfoCircleOutlined
                v-if="record.hasNightShiftOnStart"
                style="color: #1890ff"
              />
            </a-tooltip>
          </template>
        </template>

        <template #night-hours="{ record }">
          <span>{{ record.nightHours }}</span>
          <template v-if="record.hasNightShiftOnEnd">
            <span class="gray-text mr-8">({{ record.excludedHours }}h)</span>
            <a-tooltip>
              <template #title>
                These hours will be recorded in the next month
              </template>
              <InfoCircleOutlined style="color: #1890ff" />
            </a-tooltip>
          </template>
        </template>

        <template #level="{ record }">
          <span class="uppercase">{{ record.groupLevel }}</span>
        </template>

        <template #teacher="{ record }">
          <div>
            {{ record?.staff?.user?.firstName }}
            {{ record?.staff?.user?.lastName }}
          </div>
          <!-- <span class="small-text">{{ record.staff.user.email }}</span> -->
        </template>

        <template #action="{ record }">
          <template v-if="checkEditable(record)">
            <EditOutlined
              @click="() => handleEdit(record)"
              class="ml-8 mr-16"
            />
            <DeleteOutlined @click="() => handleRemove(record)" />
          </template>
        </template>

        <template #footer>
          <div class="flex">
            <b class="mr-16">Total:</b>
            <div class="mr-16">Day hours - {{ totalDayHours }}</div>
            <div class="mr-16">Night hours - {{ totalNightHours }}</div>
            <div>Payables - {{ totalPriceUsd }}$</div>
          </div>
        </template>
      </a-table>
      <div v-show="tableData.length" class="page-size-block">
        <label for="pageSize">Page size: </label>
        <ui-select
          id="pageSize"
          size="middle"
          :options="pageSizeOptions"
          v-model:value="pageSize"
        ></ui-select>
      </div>
    </div>
  </div>
</template>

<script>
import getNearestPastDate from '../utils/getNearestPastDate'
import UiTable from '../components/ui/UiTable.vue'
import UiButton from '../components/ui/UiButton.vue'
import UiDatePicker from '../components/ui/UiDatePicker.vue'
import UiSelect from '../components/ui/UiSelect.vue'
import MiniTabs from '../components/students/card/MiniTabs.vue'
import BillingPeriod from '../components/time-tracker/BillingPeriod.vue'
import TimeTrackModal, {
  MODES,
} from '../components/time-tracker/TimeTrackModal.vue'
import moment from 'moment-timezone'
import { paramsForServer } from 'feathers-hooks-common'
import { mapActions } from 'vuex'
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue'

export default {
  name: 'TimeTrackerPage',
  components: {
    UiTable,
    UiButton,
    BillingPeriod,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    InfoCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UiDatePicker,
    UiSelect,
    TimeTrackModal,
    MiniTabs,
  },
  data() {
    const isAdmin = this.$can('create', 'staff-rate')
    console.log('isAdmin', isAdmin)

    // const currentTimezone = moment.tz.guess()
    const freezeDate = moment().tz('Asia/Yerevan').date(27).startOf('day')
    console.log('Freeze date in local time:', '\n', freezeDate.toDate())

    let columns = [
      {
        title: 'Date',
        key: 'date',
        width: 110,
        slots: { customRender: 'date' },
      },
      {
        title: 'Start time',
        key: 'startTime',
        width: 90,
        slots: { customRender: 'start' },
      },
      {
        title: 'End time',
        key: 'endTime',
        width: 90,
        slots: { customRender: 'end' },
      },
      {
        title: 'Day hours',
        key: 'dayHours',
        ellipsis: true,
        slots: { customRender: 'day-hours' },
      },
      {
        title: 'Night hours',
        key: 'nightHours',
        ellipsis: true,
        slots: { customRender: 'night-hours' },
      },
      {
        title: 'Activity',
        key: 'activity',
        ellipsis: true,
        dataIndex: 'activity',
      },
      // {
      //   title: 'Purpose',
      //   key: 'purpose',
      //   ellipsis: true,
      //   dataIndex: 'purpose',
      // },
      {
        title: 'Group name',
        key: 'groupName',
        ellipsis: true,
        dataIndex: 'groupName',
      },
      {
        title: 'Group level',
        key: 'groupLevel',
        width: 100,
        slots: { customRender: 'level' },
      },
      {
        title: 'Total usd',
        key: 'totalPriceUsd',
        width: 80,
        dataIndex: 'totalPriceUsd',
      },
      {
        title: 'Teacher',
        key: 'staff',
        ellipsis: true,
        slots: { customRender: 'teacher' },
        filters: [],
      },
      {
        title: 'Action',
        key: 'action',
        ellipsis: true,
        width: 80,
        slots: { customRender: 'action' },
      },
    ]

    // TODO: find better way to identify teachers
    if (!isAdmin) {
      columns = columns.filter((c) => c.key !== 'staff')
    }

    let activityDates
    if (moment().date() > 25) {
      activityDates = [
        moment().tz('Asia/Yerevan').date(26).startOf('day'),
        moment().tz('Asia/Yerevan').add(1, 'month').date(25).endOf('day'),
      ]
    } else {
      activityDates = [
        moment()
          .tz('Asia/Yerevan')
          .subtract(1, 'month')
          .date(26)
          .startOf('day'),
        moment().tz('Asia/Yerevan').date(25).endOf('day'),
      ]
    }

    const nearestEndOfPeriod = getNearestPastDate(25, 'Asia/Yerevan', true)
    console.log(
      'Nearest end of period in local time:',
      '\n',
      nearestEndOfPeriod.toDate()
    )

    return {
      isAdmin,
      loading: false,
      tableData: [],
      columns,
      pagination: {},
      activityDates,
      freezeDate,
      nearestEndOfPeriod,
      dateFormat: 'YYYY/MM/DD',
      addModalVisibile: false,
      editModalVisibile: false,
      editData: undefined,
      modalModes: MODES,
      pageSizeOptions: [{ value: 20 }, { value: 60 }, { value: 100 }],
      pageSize: 100,
      tableScroll: { y: '70vh' },
      filters: [],
    }
  },
  computed: {
    isLockedRange() {
      return !(
        this.isAdmin ||
        (this.freezeDate.diff(this.activityDates[1]) > 0 &&
          this.activityDates[1]?.month() === this.freezeDate.month())
      )
    },
    filteredColumns() {
      return this.isLockedRange
        ? this.columns.filter((c) => c.key !== 'action')
        : this.columns
    },
    totalHours() {
      return this.tableData
        .reduce((acc, row) => acc + row.totalHours, 0)
        .toFixed(2)
    },
    totalNightHours() {
      return this.tableData
        .reduce((acc, row) => acc + row.nightHours, 0)
        .toFixed(2)
    },
    totalDayHours() {
      return (Number(this.totalHours) - Number(this.totalNightHours)).toFixed(2)
    },
    totalPriceUsd() {
      return this.tableData
        .reduce((acc, row) => acc + Number(row.totalPriceUsd), 0)
        .toFixed(2)
    },
    teacherTimezone() {
      return this.$store.state.auth?.payload?.user?.staff?.timezone
    },
  },
  watch: {
    pageSize() {
      this.fetch()
    },
    activityDates(newVal) {
      if (newVal) {
        this.fetch({
          dateRange: newVal,
        })

        console.log(
          'Selected range in local time:',
          '\n',
          newVal[0].toDate(),
          '\n',
          newVal[1].toDate()
        )
      }
    },
  },
  async created() {
    // Filters for admin
    if (this.isAdmin) {
      this.initAdminFilters()
    }
    this.fetch()
  },
  mounted() {
    console.log('isLockedRange', this.isLockedRange)
  },
  methods: {
    moment,
    ...mapActions('time-tracker', {
      removeRow: 'remove',
    }),
    async initAdminFilters() {
      const { Role } = this.$FeathersVuex.api
      let teachers

      try {
        const { data } = await Role.find(
          paramsForServer({
            joins: [
              {
                name: 'userRole',
                childs: [
                  {
                    name: 'user',
                    childs: ['staff'],
                  },
                ],
              },
            ],
            query: {
              name: 'teacher',
            },
          })
        )

        teachers = data[0].userRole.reduce((acc, ur) => {
          if (ur?.user?.staff?.id) {
            acc.push({
              text: `${ur.user?.firstName} ${ur.user?.lastName}`,
              value: ur.user.staff.id,
            })
          } else {
            console.log('Incorrect user', ur)
          }

          return acc
        }, [])
      } catch (error) {
        alert(`Teaher role not found! ${error}`)
        console.error(error)
      }

      console.log('Teachers list', teachers)
      const teachersColumn = this.columns.find((c) => c.title === 'Teacher')
      teachersColumn.filters = teachers
    },
    refreshTableData() {
      this.tableData = this.tableData.map(this.calculateEstimatedPrices)
    },
    handleAdd() {
      this.addModalVisibile = true
    },
    handleEdit(row) {
      this.editModalVisibile = true
      this.editData = row
    },
    async handleRemove(record) {
      if (confirm('Are you sure?')) {
        try {
          // Always hard remove
          await this.removeRow([
            record.id,
            paramsForServer({ disableParanoid: true }),
          ])
          this.tableData = this.tableData.filter((r) => r.id !== record.id)
        } catch (error) {
          console.error(error)
          alert(`Server error! ${error.message}`)
        }
      }
    },
    handleResponse() {
      this.addModalVisibile = false
      this.editModalVisibile = false
      this.editData = undefined
      // TODO: remove after feathers-vuex with vue 3
      this.fetch()
    },
    handleTableChange(pagination, filters) {
      if (filters?.staff) {
        this.filters = filters
      }

      const { pageSize, current } = pagination

      this.fetch({
        $limit: pageSize,
        $skip: pageSize * (current - 1),
      })
    },
    async fetch(options = {}) {
      this.loading = true
      const { TimeTracker } = this.$FeathersVuex.api
      const { dateRange, $skip } = options
      let start, end

      if (dateRange) {
        start = dateRange[0]
        end = dateRange[1]
      } else {
        start = this.activityDates[0]
        end = this.activityDates[1]
      }

      //TODO: { stuffId = currentUserId }
      const { data, total, limit } = await TimeTracker.find(
        paramsForServer({
          joins: [{ name: 'staff', childs: ['user', 'staffRate'] }],
          query: {
            // TODO: check edges
            $or: [
              {
                endTime: {
                  $gt: start,
                  $lt: end,
                },
              },
              {
                startTime: {
                  $gte: start,
                  $lt: end,
                },
              },
            ],
            staffId: this.filters?.staff?.length
              ? {
                  $in: this.filters.staff,
                }
              : undefined,
            $sort: {
              startTime: 1,
            },
            $limit: this.pageSize,
            $skip,
          },
        })
      )

      this.tableData = data.map(this.processData)
      this.pagination = {
        pageSize: limit,
        total,
      }
      this.loading = false
    },
    processData(row) {
      // Split slot if has night shift
      const start = moment(row.startTime)
      const end = moment(row.endTime)
      const teacherTimezone = row.staff.timezone

      row.hasNightShiftOnStart = this.activityDates[0].isBetween(start, end)
      row.hasNightShiftOnEnd = this.activityDates[1].isBetween(start, end)

      // Split row by range start
      if (row.hasNightShiftOnStart) {
        const minutesBeforeEndOfRow = end.diff(
          this.activityDates[0],
          'minutes',
          true
        )

        const newTotalHours = Number(
          (Math.round(minutesBeforeEndOfRow) / 60).toFixed(2)
        )

        row.excludedHours = Number((row.totalHours - newTotalHours).toFixed(2))
        row.totalHours = newTotalHours

        const nightEnd = moment(this.activityDates[0])
          .tz(teacherTimezone)
          .hours(6)
          .minutes(0)
          .seconds(0)

        // Cover if nigth end earlier than row end
        if (nightEnd.isBetween(this.activityDates[0], end)) {
          let nightMinutes = nightEnd.diff(
            this.activityDates[0],
            'minutes',
            true
          )
          row.nightHours = Number((Math.round(nightMinutes) / 60).toFixed(2))
        } else {
          row.nightHours = row.totalHours
        }
      }

      // Split row by range end
      if (row.hasNightShiftOnEnd) {
        const endOfRange = this.activityDates[1]
        const minutesBeforeEndOfRange = endOfRange.diff(start, 'minutes', true)

        const newTotalHours = Number(
          (Math.round(minutesBeforeEndOfRange) / 60).toFixed(2)
        )

        row.excludedHours = Number((row.totalHours - newTotalHours).toFixed(2))
        row.totalHours = newTotalHours

        const nightStart = moment(start)
          .tz(teacherTimezone)
          .hours(22)
          .minutes(0)
          .seconds(0)

        // Cover if row start later than night start
        if (nightStart.isBetween(start, endOfRange)) {
          let nightMinutes = endOfRange.diff(nightStart, 'minutes', true)
          row.nightHours = Number((Math.round(nightMinutes) / 60).toFixed(2))
        } else {
          row.nightHours = row.totalHours
        }
      }

      row.dayHours = Number((row.totalHours - row.nightHours).toFixed(2))

      if (row.hasNightShiftOnStart || row.hasNightShiftOnEnd) {
        const { staff } = row
        const { staffRate } = staff
        const { value, nightValue } = staffRate
        row.totalPriceUsd = Number(
          (row.dayHours * value + row.nightHours * nightValue).toFixed(2)
        )
      }

      return row
    },
    checkEditable(row) {
      const start = moment(row.startTime)
      const today = moment()

      return (
        this.isAdmin ||
        !(
          start.diff(this.nearestEndOfPeriod) <= 0 &&
          today.diff(this.freezeDate) > 0
        )
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.aps-head {
  height: inherit;
}

.p-0 {
  padding: 0px;
}

.gray-text {
  color: #7d7d7d;
}

.page-size-block {
  margin-top: -48px;
  margin-left: 1rem;
}

.vertical-scroll {
  overflow: scroll;
}
</style>
