export const activityOptions = [
  {
    label: 'Teaching',
    value: 'Teaching',
  },
  {
    label: 'Checking homework',
    value: 'Checking homework',
  },
  {
    label: 'Designing Educational materials',
    value: 'Designing Educational materials',
  },
  {
    label: 'Teaching Remedial',
    value: 'Teaching Remedial',
  },
  {
    label: 'Meeting',
    value: 'Meeting',
  },
  {
    label: 'Admin duty',
    value: 'Admin duty',
  },
  {
    label: 'Mock test',
    value: 'Mock test',
  },
]

export const activityParams = {
  Teaching: {
    hasGroupName: true,
    hasGroupLevel: true,
  },
  'Checking homework': {
    purposes: [
      {
        label: 'Regular homework',
        value: 'Regular homework',
      },
      {
        label: 'Correcting at forum',
        value: 'Correcting at forum',
      },
    ],
  },
  'Designing Educational materials': {
    purposes: [
      {
        label: 'Researching materials for class',
        value: 'Researching materials for class',
      },
      {
        label: 'Creating materials for class',
        value: 'Creating materials for class',
      },
    ],
  },
  'Teaching Remedial': {
    hasGroupName: true,
    hasGroupLevel: true,
  },
  Meeting: {
    purposes: [
      {
        label: 'General meeting',
        value: 'General meeting',
      },
      {
        label: 'Employer meeting',
        value: 'Employer meeting',
      },
      {
        label: 'Senior management meeting',
        value: 'Senior management meeting',
      },
      {
        label: 'Teacher training',
        value: 'Teacher training',
      },
      {
        label: 'Job Interview',
        value: 'Job Interview',
      },
      {
        label: 'Developing new materials meeting',
        value: 'Developing new materials meeting',
      },
    ],
  },
  'Admin duty': {
    purposes: [
      {
        label: 'Weekly report',
        value: 'Weekly report',
      },
      {
        label: 'Other documentation',
        value: 'Other documentation',
      },
    ],
  },
  'Mock test': {
    purposes: [
      {
        label: 'Preparing mock test materials',
        value: 'Preparing mock test materials',
      },
      {
        label: 'Attending mock test',
        value: 'Attending mock test',
      },
      {
        label: 'Evaluating mock test',
        value: 'Evaluating mock test',
      },
    ],
  },
}
