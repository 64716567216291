<template>
  <a-table v-bind="{ ...$attrs, ...$props }">
    <slot></slot>
  </a-table>
</template>

<script>
export default {
  name: 'UiTable',
  inheritAttrs: false
}
</script>

<style scoped></style>
