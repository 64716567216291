<template>
  <a-date-picker size="large" v-bind="{ ...$attrs, ...$props }"></a-date-picker>
</template>

<script>
export default {
  name: 'UiDatePicker',
  inheritAttrs: false
}
</script>

<style lang="scss">
</style>
