<template>
  <a-time-picker class="picker" v-bind="{ ...$attrs, ...$props }" size="large"></a-time-picker>
</template>

<script>
export default {
  name: 'UiTimePicker',
  inheritAttrs: false
}
</script>

<style lang="scss">
</style>
