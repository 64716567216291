<template>
  <div class="flex flex-column flex-vertical-center">
    <div class="title">BILLING PERIOD</div>
    <div class="flex flex-vertical-center">
      <a-tooltip>
        <template #title> Previous Period </template>
        <LeftOutlined
          class="month-arrow mr-16"
          @click="() => moveMonthRange(false)"
        />
      </a-tooltip>
      <div class="regular mr-16">
        {{ modelValue[0].format(format) }}
        –
        {{ modelValue[1].format(format) }}
        <LockOutlined v-show="isLockedRange" />
      </div>
      <a-tooltip>
        <template #title> Next Period </template>
        <RightOutlined
          class="month-arrow"
          @click="() => moveMonthRange(true)"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import {
  LeftOutlined,
  RightOutlined,
  LockOutlined,
} from '@ant-design/icons-vue'
import moment from 'moment-timezone'

export default {
  name: 'BillingPeriod',
  components: {
    LeftOutlined,
    RightOutlined,
    LockOutlined,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    freezeDate: {
      type: Object,
      required: true,
    },
    nearestEndOfPeriod: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      format: 'MMM DD, YYYY',
    }
  },
  computed: {
    isLockedRange() {
      return !(
        this.isAdmin ||
        (this.freezeDate.diff(this.modelValue[1]) > 0 &&
          this.modelValue[1]?.month() === this.freezeDate.month())
      )
    },
  },
  methods: {
    moveMonthRange(isForward) {
      if (isForward) {
        this.$emit('update:modelValue', [
          moment(this.modelValue[0])
            .tz('Asia/Yerevan')
            .add(1, 'month')
            .date(26)
            .startOf('day'),
          moment(this.modelValue[0])
            .tz('Asia/Yerevan')
            .add(2, 'month')
            .date(25)
            .endOf('day'),
        ])
      } else {
        this.$emit('update:modelValue', [
          moment(this.modelValue[0])
            .tz('Asia/Yerevan')
            .subtract(1, 'month')
            .date(26)
            .startOf('day'),
          moment(this.modelValue[0]).tz('Asia/Yerevan').date(25).endOf('day'),
        ])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
}

.regular {
  font-size: 12px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
}

.month-arrow {
  cursor: pointer;

  :hover {
    color: rgba(24, 144, 255, 1);
  }
}
</style>
